@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.CardList {

  &.cardRowContainer {
    @extend .simple-spaced-rows;
  }

  .card-row {
    justify-content: center;

    .card-col {
      // Take full width on smaller screens
      @extend .full-width-centered-col, .col-md-auto;
    }
  }
}
