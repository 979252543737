@import "../../../../scss/bootstrap-configuration";
@import "../../../../scss/index";

.ReportSummaryStats {

  $max-width: $report-card-width;

  border-color: $exotalent-orange;
  border-width: 7px;
  border-style: solid;
  padding: 0.5rem;
  white-space: nowrap;
  text-align: center;

  // Make component up to a certain width on smaller screens and center in available space
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $max-width;

  // On larger screens, make component take fixed width
  @include media-breakpoint-up(sm) {
    width: $max-width;
  }

  .title {
    font-family: $font-family-bold;
    font-size: $font-size-base * 1.2;
  }
}
