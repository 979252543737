@import "../../../../scss/bootstrap-configuration";
@import "~bootstrap/scss/grid";
@import "../../../../scss/bootstrap-utilities-api";
@import "../../../../scss/layout";

.TalentPoolDetailsBlock {

  &.mainRowContainer {
    @extend .triple-spaced-rows;
  }

  .topRow {
    justify-content: space-between;
  }

  .title-col {
    // Center text and take full width on smaller screens
    @extend .text-center, .text-lg-start;
    @extend .col-12, .col-lg-auto;
  }

  .stats-col {
    // Take full width on smaller screens
    @extend .full-width-centered-col, .col-lg-auto;
  }
}
