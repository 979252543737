@import "../../../scss/_layout";

.HeaderContainer {

  .navbar {
    // When menu has fixed position, it is out of the page body, so we must repeat to compensate
    // in case a right scrollbar appears, to avoid moving items
    @extend .compensate-right-scrollbar;

    // Align menu items to the top of the menu bar
    .navbar-container {
      align-items: end;
    }
  }

  .logo {
    height: $menu-logo-height;
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-nav {
    align-items: flex-end;
  }
}
