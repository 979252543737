@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.MarketCardList {

  &.cardRowContainer {
    @extend .simple-spaced-rows;
  }

  .card-row {
    justify-content: center;

    .card-col {
      // Take full width on smaller screens so that content can stretch to full width
      // Adjust width to fixed-width content on bigger screen
      @extend .col-12, .col-sm-auto;
    }
  }
}
