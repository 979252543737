@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.BasePage {

  // To push footer to the end of the page when content is short
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header-nav {
    // Use a variable here because we need to compute the total header height somewhere else
    margin-bottom: $header-margin-bottom;
  }

  .main-container-loader, .compensate-right-scrollbar, .visible-contents {
    // To push footer to the end of the page when content is short
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  // Give some air at the end of the page
  .main-container {
    margin-bottom: $content-margin-bottom;
  }
}
