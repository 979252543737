@import "../../../../scss/bootstrap-configuration";
@import "../../../../scss/index";
@import "../../../../scss/layout";

.CandidatePreview {

  // Space between rows
  &.mainRowContainer {
    @extend .double-spaced-rows;
  }

  $max-width: $candidate-card-width;

  @extend .card-border;

  height: 10rem;
  padding: 1rem;

  // Make component up to a certain width on smaller screens
  width: 100%;
  max-width: $max-width;

  // On larger screens, make component take fixed width
  @include media-breakpoint-up(sm) {
    width: $max-width;
  }

  &:hover {
    background-color: $light-background;
  }

  .name-row {
    align-items: center;
  }

  .name-col {
    font-family: $font-family-bold;
  }

  .note-col {
    font-family: $font-family-normal-italic;
  }
}
