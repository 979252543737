@import "../../../../scss/bootstrap-configuration";
@import "../../../../scss/index";
@import "../../../../scss/layout";

.ReportPreview {

  // Space between rows
  &.mainRowContainer {
    @extend .double-spaced-rows;
  }

  $max-width: $report-card-width;

  @extend .card-border;

  height: 10rem;
  padding: 1rem;

  text-align: center;

  // Make component up to a certain width on smaller screens
  width: 100%;
  max-width: $max-width;

  // On larger screens, make component take fixed width
  @include media-breakpoint-up(sm) {
    width: $max-width;
  }

  &:hover {
    background-color: $light-background;
  }

  .name-col {
    font-family: $font-family-bold;
    font-size: $font-size-base * 1.2;
  }

  .candidate-col {
    .number {
      font-size: $h1-font-size;
      font-family: $font-family-bolder;
    }

    color: $exotalent-red;
    font-size: $font-size-base * 1.2;
    font-family: $font-family-bold;
  }
}
