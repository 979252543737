@import "./_bootstrap-configuration";
@import "~bootstrap/scss/mixins/_buttons";

// Titles
h1 {
  font-family: $font-family-bolder;
  margin-bottom: 0;
}

h2 {
  font-family: $font-family-bolder;
  color: $subtitle-gray;
  font-weight: bold;
  margin-bottom: 0;
}

// Buttons
.ActionButtonWithIcon {
  &.btn {
    border-width: 2px;
    font-family: $font-family-bold;
  }

  @include button-variant(
          $background: white,
          $border: $exotalent-orange,
          $color: $exotalent-blue,
          $hover-background: $exotalent-orange,
          $hover-color: white
  )
}

// Menu items
.MenuItem {
  .active-item {
    // Overwrite the bold style for a different font family
    font-family: $font-family-bolder;
  }
}

// Links
a {
  text-decoration-thickness: from-font;
}

// User-editable html (from a wysiwyg)
b, strong {
  font-family: $font-family-bold;
}

i, em {
  font-family: $font-family-normal-italic;
}

b > i, i > b, strong > em, em > strong {
  font-family: $font-family-bold-italic;
}

// Report and candidate cards
$report-card-width: 25rem;
$candidate-card-width: 25rem;

// Card borders
.card-border {
  border-style: solid;
  border-color: $light-background;
  border-width: $border-width * 4;
}
