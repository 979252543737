@import "../../../../scss/bootstrap-configuration";
@import "../../../../../node_modules/bootstrap/scss/grid";
@import "../../../../scss/layout";
@import "../../../../scss/bootstrap-utilities-api";

.PoolCandidateDetails {

  &.mainRowContainer {
    @extend .simple-spaced-rows;
  }

  .topRow {
    justify-content: space-between;
  }

  .title-col {
    // Center text and take full width on smaller screens
    @extend .text-center, .text-lg-start;
    @extend .col-12, .col-lg;

    h1 {
      font-family: $font-family-bolder;
      font-size: $h3-font-size;
      margin-bottom: 0;
    }

    .h2 {
      color: $subtitle-gray;
      font-family: $font-family-bolder;
      font-size: $h4-font-size;
      margin-bottom: 0;
    }
  }

  .name-col {
    h3 {
      font-family: $font-family-bold;
      font-size: $h5-font-size;
      margin-bottom: 0;
    }
  }

  h2.category-name {
    font-family: $font-family-bold;
  }

  .resume-col {
    // Take full width on smaller screens
    @extend .full-width-centered-col, .col-lg-auto;
  }

  .button-row {
    margin-top: 2rem !important;
    text-align: center;
  }
}
