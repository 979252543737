@import "./_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

// Add a dynamic left padding when a scrollbar appears on the right, to avoid content from moving left
// (does not work with right-aligned content)
.compensate-right-scrollbar {
  padding-left: calc(100vw - 100%);
  padding-right: 0;
}

// Call this mixin on a container of rows to insert vertical space between rows. When a row has multiple columns and
// they must wrap, the same vertical space will be inserted between the new rows.
@mixin spaced-rows($vspace) {
  // First row has a negative margin (do not recurse deeper than first level)
  > .row:nth-child(1) {
    margin-top: -$vspace;
  }

  // Every column has a top margin so that there is a vertical space between lines when columns wrap
  // (do not recurse deeper than first level of rows)
  > .row > .col {
    margin-top: $vspace;
  }
}

// Insert a standard vertical space between children rows of a container
.simple-spaced-rows {
  @include spaced-rows(1rem);
}

// Insert a standard vertical space between children rows of a container
.double-spaced-rows {
  @include spaced-rows(2rem);
}

// Insert a vertical space three times the standard space, between children rows of a container
.triple-spaced-rows {
  @include spaced-rows(3rem);
}

// Add a standard vertical space between a container's first-level children
.spaced-children {
  > * + * {
    margin-top: 1rem !important;
  }
}

.double-spaced-children {
  > * + * {
    margin-top: 2rem !important;
  }
}

// Add a vertical space between columns of a row but only when they wrap
// Use margin-bottom instead of margin-top so that it does not override
// margin-top when the row is inside a .spaced-children parent.
.wrap-spaced-children {
  $vspace: 1rem;

  margin-bottom: -$vspace;
  > * {
    margin-bottom: $vspace;
  }
}

// Content will be centered vertically
.center-vertically {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

// Make column take full width and center its child (when child does not stretch to full width)
.full-width-centered-col {
  @extend .col-12;

  > * {
    // Center item in available space when it doesn't take full width
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

// We need to know the height of the header to shift up the image on the report home page
$menu-logo-height: 7rem;
$header-margin-bottom: 2rem;
$header-height: ($navbar-padding-y * 2) + ($navbar-brand-padding-y * 2) + $menu-logo-height + $header-margin-bottom;

// Give some air at the end of the page, before the footer
$content-margin-bottom: 4rem;

// Put the back button just on top of the title
.back-button-container {
  position: relative;

  .back-button {
    position: absolute;
    top: -2rem;
    left: 0;
  }
}
