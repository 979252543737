@import "../../../../scss/bootstrap-configuration";
@import "../../../../scss/bootstrap-utilities-api";
@import "../../../../scss/layout";

.ReportHomeBlock {

  // Double space between rows
  .content-col {
    @extend .double-spaced-children;

    .title-col, .action-row {
      // Center text on smaller screens
      @extend .text-center, .text-lg-start;
    }
  }

  .text-content {
    text-decoration-thickness: from-font;

    b, strong {
      font-family: $font-family-bold;
    }

    i, em {
      font-family: $font-family-normal-italic;
    }

    b > i, b > em, strong > i, strong > em, i > b, em > b, i > strong, em > strong {
      font-family: $font-family-bold-italic;
    }
  }

  .image-col {
    // Display image column only on large screen and up
    @extend .d-none, .d-lg-block;

    @extend .col-auto;

    img {
      padding-right: 5rem;

      // Make the image big enough to reach the top of footer on any screen size. This depends on the amount of text.
      @include media-breakpoint-only(lg) {
        width: 37rem;
      }

      @include media-breakpoint-up(xl) {
        width: 30rem;
      }

      // Make the image flush with top of page and top of footer
      margin-top: -$header-height;
      margin-bottom: -$content-margin-bottom;
    }
  }
}
