@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../scss/_layout";

.Footer {
  padding-top: 2rem;
  padding-bottom: 2rem;

  // Double the spacing between rows
  @extend .double-spaced-children;

  // Text color is white, including links
  &, & a {
    color: $footer-text-color;
  }

  a:hover {
    color: $footer-active-color;
  }

  a {
    // Same transition on color as corporate website when hovering links
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: color;
    transition-timing-function: ease-in-out;
  }

  .horizontal-line {
    // We use a border instead of an hr to keep the same look as the corporate website
    border-top-color: rgba(255, 255, 255, 0.1);
    border-top-width: 2px;
    border-top-style: solid;
  }

  .logo-row {
    align-items: flex-end;

    .exotalent-logo {
      margin-right: 2rem;

      // Use a smaller logo on smaller screens
      width: 5rem;
      @include media-breakpoint-up(lg) {
        width: 10rem;
      }
    }

    .exotalent-email {
      vertical-align: bottom;
    }

    .sourcing-col {
      @extend .col-auto;
    }
  }

  .magellan-row {

    margin-top: 0 !important;
    // Push address column to the right
    justify-content: space-between;

    .magellan-col {
      @extend .spaced-children;

      // On smaller screens, center content
      justify-content: center;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }

      // Push the second row to the bottom of available space
      @extend .row;
      align-content: space-between;

      // Make sure this column takes the whole width on smaller screens so
      // that we know we have to center elements on that screen sizes
      @extend .col-12, .col-lg-6;

      .division-col {

        // Center text when alone on its row
        @include media-breakpoint-down("lg") {
          text-align: center;
        }

        .magellan-logo {
          width: 130px;

          // Align text in logo with text around logo
          transform: matrix(1, 0, 0, 1, 0, 12.5);
          vertical-align: baseline;
        }
      }

      .card-group-col {

        img {
          width: 4rem;
        }

        // Make even x and y space around logos when wrapped
        .card-container {
          overflow: hidden;
          @extend .g-0;

          .card-row {
            @extend .gx-1;
            @extend .gy-1;

            // Align items to the left
            justify-content: flex-start;

            .card-col {
              @extend .col-auto;
            }

            // Center cards when alone on its row
            @include media-breakpoint-down("lg") {
              justify-content: center;
            }
          }
        }
      }
    }

    .address-col {

      text-align: right;
      // Center text when alone on its row
      @include media-breakpoint-down("lg") {
        text-align: center;
      }

      // Make the column take the whole width on smaller screens
      @extend .col-12, .col-lg-6;

      // Move down the first line of text so that its baseline is aligned with the magellan logo baseline in the column to its left
      margin-top: 30px;

      .postal-address-col {

        p {
          margin-bottom: 0;
        }
      }

      .phone-row {
        margin-top: 1rem;

        .phone-number {
          font-family: $font-family-bold;
        }
      }

      .linkedin-row {
        justify-content: flex-end;
        align-items: flex-end;

        // Center text when alone on its row
        @include media-breakpoint-down("lg") {
          justify-content: center;
        }
      }

      .linkedin-col {
        @extend .col-auto;

        .linkedin-logo {
          width: 2rem;
        }
      }

      .contact-col {
        @extend .col-auto;
      }
    }
  }
}
