// Colors
$exotalent-blue: #211D30;
$exotalent-orange: #F9A159;
$exotalent-red: #f1603f;
$subtitle-gray: #737590;
$light-background: #ededee;
$dark-background: #d6d5db;
$error-red: red;
$success-green: green;
$warning-orange: orange;
$footer-text-color: white;
$footer-active-color: #fbd0c6;
