@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_modal";
@import "../../../scss/_modals";

.ContentModal {

  .modal-dialog {
    // Center dialog vertically
    @extend .modal-dialog-centered;
  }

  .modal-body {

    // Adjust padding, make it smaller on smaller screens
    padding: 2rem;
    @include media-breakpoint-up(md) {
      padding: 4rem;
    }
  }
}
