@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.SplashScreen {
  text-align: center;

  animation-name: splashFadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in;

  .logo-row {
    // align row content vertically over full page
    min-height: 100vh;
    align-items: center;
    @extend .g-0; // Remove gutter to avoid scrollbars
  }

  .logo {
    width: 200px;
  }

  @keyframes splashFadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
}
