@import "../../../../scss/bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/index";

.TalentPoolDetailsStats {

  $max-width: $report-card-width;

  border-color: $exotalent-orange;
  border-width: 7px;
  border-style: solid;
  padding: 0.5rem;
  white-space: nowrap;
  text-align: center;

  // Make component up to a certain width on smaller screens and center in available space
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $max-width;

  // On larger screens, make component take fixed width
  @include media-breakpoint-up(sm) {
    width: $max-width;
  }

  .stats-line {
    // Equal blank space between columns
    display: flex;
    justify-content: space-evenly;
    > * {
      @extend .col-auto;
    }
  }
}
