@import "../../../../scss/bootstrap-configuration";
@import "~bootstrap/scss/grid";
@import "../../../../scss/bootstrap-utilities-api";
@import "../../../../scss/layout";

.ReportDetailsBlock {

  &.mainRowContainer {
    @extend .triple-spaced-rows;
  }

  .topRow {
    justify-content: space-between;
  }

  .title-col {
    // Center text and take full width on smaller screens
    @extend .text-center, .text-lg-start;
    @extend .col-12, .col-lg-auto;
  }

  .image-col {
    @extend .col-auto;
    // Hide column on smaller screens
    @extend .d-none, .d-lg-block;

    .person-image {
      width: 150px;
    }
  }

  .client-name {
    text-transform: uppercase;
  }
}
