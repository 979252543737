.Menu {

  .MenuItem {
    .menu-item-link {
      text-transform: uppercase;
    }
  }
}



